<template>
  <div id="app">
    <b-sidebar id="sidebar-right" no-header right backdrop>
      <!-- drawer-content -->

      <!-- menu close icon -->
      <div class="float-right my-4 mr-4 bar-container" v-b-toggle.sidebar-right>
        <div class="bar bar1"></div>
        <div class="bar bar2"></div>
        <div class="bar bar3"></div>
      </div>

      <!-- drawer menu -->
      <DrawerMenu v-bind:pages="pages"></DrawerMenu>
    </b-sidebar>

    <!-- main content -->

    <!-- logo and menu -->
    <div class="container text-left fg-lightblue">
      <div class="row">
        <!-- logo -->
        <div class="col-7 col-md-6">
          <a href="/">
            <img
              src="@/assets/LG_FC_KnapKind_witteondergrond.png"
              class="logo mt-2 mb-3"
            />
          </a>
        </div>

        <!-- login -->
        <div class="col-5 col-md-3 offset-md-1">
          <div class="float-left">
            <div class="float-left fg-lightblue mt-3">
              <a href="http://digi-po.knapkind.nl/pp-lessen.php"
                >Login plus pagina PO</a
              >
            </div>
            <div class="float-left fg-lightblue">
              <a href="http://digi-vo.knapkind.nl/pp-lessen.php"
                >Login plus pagina VO</a
              >
            </div>
            <img src="@/assets/login.png" class="ml-2 login" />
          </div>
        </div>

        <!-- menu hamburger icon -->
        <div class="col-5 col-md-2">
          <div
            class="float-right mt-3 ml-5 bar-container"
            v-b-toggle.sidebar-right
          >
            <div class="bar"></div>
            <div class="bar"></div>
            <div class="bar"></div>
          </div>
          <div class="float-right mt-4">MENU</div>
        </div>
      </div>
    </div>

    <!-- router views -->
    <router-view />

    <cookie-law buttonText="Akkoord" buttonClass="btn btn-success btn-sm">
      <div class="small" slot="message">
        Deze website maakt gebruik van cookies om ervoor te zorgen dat u de
        beste ervaring op onze website krijgt. Voor meer informatie klik op de
        volgende link:
        <a
          href="/uploads/2019_Privacyverklaring-Simone-Busscher.pdf"
          >privacybeleid</a
        >
      </div>
    </cookie-law>
  </div>
</template>

<script>
import Vue from "vue";
import CookieLaw from "vue-cookie-law";
import { SidebarPlugin } from "bootstrap-vue";

import "bootstrap/dist/css/bootstrap.css";
import "bootstrap-vue/dist/bootstrap-vue.css";

Vue.use(SidebarPlugin);

import VueCookies from "vue-cookies";
Vue.use(VueCookies);

import DrawerMenu from "@/components/DrawerMenu.vue";

const Home = () => import(/* webpackChunkName: "home" */ "@/views/Home.vue");
const Main = () => import(/* webpackChunkName: "main" */ "@/views/Main.vue");
const MainWide = () =>
  import(/* webpackChunkName: "mainWide" */ "@/views/MainWide.vue");

export default {
  data() {
    return {};
  },
  components: {
    DrawerMenu,
    CookieLaw,
  },
  computed: {
    pages() {
      let pages = this.$store.getters.pages;
      // console.log('app.vue computed pages', pages.length);
      if (pages.length > 0) {
        this.getRoutes();
        return pages;
      }
    },
  },
  created() {
    // console.log('app.vue');
  },
  methods: {
    getRoutes() {
      let response = this.$store.getters.pages;

      let routes = [];
      for (let i = 0; i < response.length; i++) {
        let path = response[i].path;

        let component;
        if (path == "") {
          component = Home;
        } else if (path == "ontspannen-naar-eindexamen") {
          component = MainWide;
        } else {
          component = Main;
        }

        const element = {
          path: "/" + path,
          name: path,
          component: component,
          props: { route: path },
        };
        routes.push(element);
      }

      routes.push({
        path: "/cms",
        name: "cms",
        component: () =>
          import(/* webpackChunkName: "cms" */ "@/views/Cms.vue"),
        props: { route: "/cms" },
      });

      // routes.push({
      // 	path: '/login',
      // 	name: 'login',
      // 	component: () => import(/* webpackChunkName: "login" */ '@/views/Login.vue'),
      // 	props: { route: '/login' }
      // });

      this.$router.addRoutes(routes);

      // uncomment this for generating info for sitemap.txt file
      // console.log('found routes', routes);
      // let url = 'http://knapkind.nl';
      // routes.forEach(element => {
      // 	console.log(url + element.path);
      // });
    },
  },
  watch: {
    $route: function(to, from) {
      console.log(from.name, "=>", to);
      // if (to.name == 'cms') {
      // 	if (this.$cookies.get('user_session') != '25j_7Sl6xDq2Kc3ym0fmrSSk2xV2XkUkX') {
      // 		console.log('cookie not okay');
      // 		this.$router.push('/login');
      // 	}
      // }
      let id = this.$store.getters.getPageId(to.name);
      this.$store.dispatch("getQuarks", id);

      if (this.drawerActive) {
        this.drawerActive = false;
        this.$refs.drawer.toggle();
      }
    },
  },
};
</script>

<style>
@import url("https://fonts.googleapis.com/css?family=Barlow|Barlow+Condensed|Chelsea+Market&display=swap");

h1 {
  font-family: Chelsea Market, sans-serif;
  font-size: 18pt;
  font-weight: 500;
  font-style: normal;
  text-transform: uppercase;
}

h2 {
  font-family: Chelsea Market, sans-serif;
  font-size: 18pt;
  font-weight: 400;
  font-style: normal;
  text-transform: uppercase;
}

h3 {
  font-size: 20px;
  font-weight: 400;
  font-style: normal;
  text-transform: uppercase;
}

h4 {
  font-size: 20px;
  font-weight: 400;
  font-style: normal;
}

.quote {
  /* font-size: 21px; */
  font-weight: 400;
  font-style: italic;
}

#app {
  font-family: Barlow, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: left;
  color: #2c3e50;
}

.kop {
  font-family: Chelsea Market, sans-serif;
}

a {
  color: #82358c;
}

a:hover {
  color: #f39200;
  text-decoration: none;
}

/* foreground colors */
.fg-royal {
  color: #fdd493;
}
.fg-apricot {
  color: #f39200;
}
.fg-pink {
  color: #82358c;
}
.fg-light-pink {
  color: #f0d6d7;
}
.fg-red {
  color: #f0800e;
}

.fg-lightblue {
  color: #009fe3;
}

/* background colors */
.bg-white {
  background-color: white;
}
.bg-royal {
  background-color: #fdd493;
}
.bg-apricot {
  background-color: #f39200;
}
.bg-purple {
  background-color: #82358c;
}
.bg-light-pink {
  background-color: #f0d6d7;
}
.bg-orange {
  background-color: #f0800e;
}
.bg-black {
  background-color: black;
}

.bg-lightblue {
  background-color: #009fe3;
}

.bg-green {
  background-color: #74af27;
}

/* button hovered state */
.hovered:hover {
  cursor: pointer;
  opacity: 0.8;
}

/* aspect ratio */

.aspect-ratio-box {
  margin: 0;
  height: 0;
  overflow: hidden;
  padding-top: 33%;
  background: yellowgreen;
  position: relative;
}
.aspect-ratio-box-inside {
  margin: 0;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

/* control aspect ratio via custom property */
/* <div style="--aspect-ratio:3;" class="bg-royal">
    <div class="aspect-ratio-box-inside">Happy Birthday</div>
</div> */

[style*="--aspect-ratio"] > :first-child {
  width: 100%;
}
[style*="--aspect-ratio"] > img {
  height: auto;
}
@supports (--custom: property) {
  [style*="--aspect-ratio"] {
    position: relative;
  }
  [style*="--aspect-ratio"]::before {
    content: "";
    display: block;
    padding-bottom: calc(100% / (var(--aspect-ratio)));
  }
  [style*="--aspect-ratio"] > :first-child {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
  }
}

.logo {
  max-width: 200px;
}

.login {
  margin-top: 19px;
  max-width: 35px;
}

/* hamburger icon */
.bar {
  width: 35px;
  height: 5px;
  background-color: #009fe3;
  margin: 6px 0;
}

.bar-container {
  /* display: inline-block; */
  cursor: pointer;
}

.bar1 {
  -webkit-transform: rotate(-45deg) translate(-8px, 8px);
  transform: rotate(-45deg) translate(-8px, 7px);
}
.bar2 {
  opacity: 0;
}
.bar3 {
  -webkit-transform: rotate(45deg) translate(-8px, -8px);
  transform: rotate(45deg) translate(-8px, -8px);
}
</style>
